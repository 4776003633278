import { useState, useLayoutEffect } from 'react';

export const useWidth = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);

  return width;
};
