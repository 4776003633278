import { useMemo } from 'react';
import { HubConnectionBuilder } from '@aspnet/signalr';

export const useSignalr = (
  path: string,
  functions: { [method: string]: (...args: any[]) => void } = {}
) =>
  useMemo(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API}${path}`)
      .build();

    Object.entries(functions).forEach(([method, fn]) => {
      connection.on(method, fn);
    });

    connection.start();

    return connection;
  }, [path, functions]);
