import React, { useState, useMemo } from 'react';
import { Spinner } from '../components/spinner';
import styles from './home.module.css';
import { useSignalr } from '../signalr/useSignalr';
import { Colour } from '../chess/types';

interface Props {
  startGame: (gameId: string, colour: Colour, playerId: string) => void;
}

export const HomeView: React.FC<Props> = ({ startGame }) => {
  const [findingGame, setFindingGame] = useState(false);
  const connection = useSignalr(
    '/pair',
    useMemo(
      () => ({
        GameFound: startGame
      }),
      [startGame]
    )
  );

  const findGame = () => {
    setFindingGame(true);
    connection.invoke('FindGame');
  };

  return (
    <main>
      <h1 className={styles.title}>Chaoess</h1>
      <div className={styles.bar}>
        {findingGame ? (
          <>
            <span>Finding Game...</span>
            <Spinner />
          </>
        ) : (
          <button className={styles.button} onClick={findGame}>
            Find game
          </button>
        )}
      </div>
    </main>
  );
};
