import React, { useState, useCallback } from 'react';
import { getTouchPosition } from './positions';

export const useMousePosition = () => {
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  const onMouseMove = useCallback((e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setPosition({ x, y });
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent) => {
    setPosition(getTouchPosition(e));
  }, []);

  const onMouseLeave = useCallback((e: React.MouseEvent) => {
    setPosition(null);
  }, []);

  return {
    events: { onMouseMove, onMouseLeave, onTouchMove },
    position
  };
};
