import React, { useState, useCallback } from 'react';
import { HomeView } from './views/home';
import './App.css';
import { GameView } from './views/game';
import { Colour } from './chess/types';

const App: React.FC = () => {
  const [game, setGame] = useState<string | null>(null);
  const [colour, setColour] = useState<Colour | null>(null);
  const [playerId, setPlayerId] = useState<string | null>(null);

  const startGame = useCallback(
    (gameId: string, colour: Colour, playerId: string) => {
      setGame(gameId);
      setColour(colour);
      setPlayerId(playerId);
    },
    []
  );

  return game && colour && playerId ? (
    <GameView gameId={game} colour={colour} playerId={playerId} />
  ) : (
    <HomeView startGame={startGame} />
  );
};

export default App;
