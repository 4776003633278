import React, { useState, useMemo } from 'react';
import { useSignalr } from '../signalr/useSignalr';
import { Chessboard } from '../components/chessboard';
import { Colour } from '../chess/types';

interface Props {
  gameId: string;
  colour: Colour;
  playerId: string;
}

export const GameView: React.FC<Props> = ({ gameId, colour, playerId }) => {
  const [position, setPosition] = useState<string>(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR'
  );

  const connection = useSignalr(
    `/game?gameid=${gameId}&playerid=${playerId}`,
    useMemo(
      () => ({
        UpdateBoard: (fen: string, check: string) => {
          setPosition(fen);
          console.log('CHECK', check);
        },
        EndGame: (winner: string) => {
          alert('Winner is ' + winner);
        }
      }),
      []
    )
  );

  return (
    <main>
      <Chessboard
        onMove={(sourceSquare, targetSquare) => {
          connection.invoke('MakeMove', sourceSquare, targetSquare);
        }}
        fen={position}
        orientation={colour}
      />
    </main>
  );
};
