import React, { useState, useRef, TouchEvent } from 'react';

import { useMousePosition } from '../utils/useMousePosition';
import { parseFen } from '../chess/fen';

import styles from './chessboard.module.css';
import wp from './assets/wp.svg';
import wn from './assets/wn.svg';
import wb from './assets/wb.svg';
import wr from './assets/wr.svg';
import wq from './assets/wq.svg';
import wk from './assets/wk.svg';
import bp from './assets/bp.svg';
import bn from './assets/bn.svg';
import bb from './assets/bb.svg';
import br from './assets/br.svg';
import bq from './assets/bq.svg';
import bk from './assets/bk.svg';
import { useWidth } from '../utils/useWidth';
import { Colour } from '../chess/types';
import { getTouchPosition } from '../utils/positions';

const getImgSrc = ({ type, colour }: { type: string; colour: string }) => {
  switch (type) {
    case 'pawn':
      return colour === 'white' ? wp : bp;
    case 'knight':
      return colour === 'white' ? wn : bn;
    case 'bishop':
      return colour === 'white' ? wb : bb;
    case 'rook':
      return colour === 'white' ? wr : br;
    case 'queen':
      return colour === 'white' ? wq : bq;
    case 'king':
      return colour === 'white' ? wk : bk;
    default:
      throw new Error(type + ' is not a piece');
  }
};

const getSquare = (
  width: number,
  orientation: Colour,
  x: number,
  y: number
) => ({
  file:
    orientation === 'white'
      ? Math.floor((x * 8) / width)
      : 7 - Math.floor((x * 8) / width),
  rank:
    orientation === 'white'
      ? 7 - Math.floor((y * 8) / width)
      : Math.floor((y * 8) / width)
});

const getPosition = (
  width: number,
  orientation: Colour,
  file: number,
  rank: number
) => ({
  x: orientation === 'white' ? (file * width) / 8 : ((7 - file) * width) / 8,
  y: orientation === 'white' ? ((7 - rank) * width) / 8 : (rank * width) / 8
});

interface Props {
  fen: string;
  onMove: (from: string, to: string) => void;
  orientation?: Colour;
}

const getSquareNotation = (file: number, rank: number) =>
  `${String.fromCharCode(97 + file)}${rank + 1}`;

export const Chessboard: React.FC<Props> = ({
  fen,
  onMove,
  orientation = 'white'
}) => {
  const [activePiece, setActivePiece] = useState<string | null>(null);
  const [moveStart, setMoveStart] = useState<string | null>(null);
  const { position: mousePosition, events } = useMousePosition();
  const out = useRef<HTMLDivElement>(null);
  const width = useWidth(out);
  const position = parseFen(fen);

  const pointerUp = () => {
    setActivePiece(null);
    if (width && mousePosition) {
      const square = getSquare(
        width,
        orientation,
        mousePosition.x,
        mousePosition.y
      );
      const moveEnd = getSquareNotation(square.file, square.rank);
      if (moveStart && moveEnd !== moveStart) {
        onMove(moveStart, moveEnd);
      }
      setMoveStart(null);
    }
  };

  const touchUp = (e: TouchEvent) => {
    const touchPosition = getTouchPosition(e);
    setActivePiece(null);
    if (width && touchPosition) {
      const square = getSquare(
        width,
        orientation,
        touchPosition.x,
        touchPosition.y
      );
      const moveEnd = getSquareNotation(square.file, square.rank);
      if (moveStart && moveEnd !== moveStart) {
        onMove(moveStart, moveEnd);
      }
      setMoveStart(null);
    }
  };

  const pointerDown = () => {
    if (width && mousePosition) {
      const square = getSquare(
        width,
        orientation,
        mousePosition.x,
        mousePosition.y
      );
      const piece = position[square.rank][square.file];
      if (piece) {
        setActivePiece(getImgSrc(piece));
        setMoveStart(getSquareNotation(square.file, square.rank));
      } else {
        setActivePiece(null);
        setMoveStart(null);
      }
    }
  };

  const touchDown = (e: TouchEvent) => {
    const touchPosition = getTouchPosition(e);
    if (width && touchPosition) {
      const square = getSquare(
        width,
        orientation,
        touchPosition.x,
        touchPosition.y
      );
      const piece = position[square.rank][square.file];
      if (piece) {
        setActivePiece(getImgSrc(piece));
        setMoveStart(getSquareNotation(square.file, square.rank));
      } else {
        setActivePiece(null);
        setMoveStart(null);
      }
    }
  };

  const pointerMove = events.onMouseMove;

  return (
    <div
      className={styles.board}
      onMouseMove={pointerMove}
      onTouchMove={events.onTouchMove}
      onMouseLeave={e => {
        events.onMouseMove(e);
        setActivePiece(null);
      }}
      onMouseUp={pointerUp}
      onTouchEnd={touchUp}
      onMouseDown={pointerDown}
      onTouchStart={touchDown}
      ref={out}
    >
      {[...Array(64)].map(() => (
        <div className={styles.square} />
      ))}
      {width &&
        position
          .flatMap((pieces, rank) =>
            pieces.map((piece, file) => piece && { ...piece, file, rank })
          )
          .map(
            piece =>
              piece && (
                <img
                  alt={`${piece.colour} ${piece.type}`}
                  key={`${piece.file}${piece.rank}`}
                  width={width / 8}
                  height={width / 8}
                  draggable={false}
                  src={getImgSrc(piece)}
                  style={{
                    position: 'absolute',
                    top: getPosition(width, orientation, piece.file, piece.rank)
                      .y,
                    left: getPosition(
                      width,
                      orientation,
                      piece.file,
                      piece.rank
                    ).x
                  }}
                />
              )
          )}
      {activePiece && mousePosition && width && (
        <img
          alt={'active piece'}
          src={activePiece}
          draggable={false}
          style={{
            position: 'absolute',
            top: mousePosition.y - width / 16,
            left: mousePosition.x - width / 16,
            width: width / 8,
            height: width / 8,
            opacity: 0.4,
            pointerEvents: 'none'
          }}
        />
      )}
    </div>
  );
};
