const getPieceType = (fenPieceType: string) => {
  switch (fenPieceType.toLowerCase()) {
    case 'p':
      return 'pawn';
    case 'b':
      return 'bishop';
    case 'n':
      return 'knight';
    case 'r':
      return 'rook';
    case 'q':
      return 'queen';
    case 'k':
      return 'king';
    default:
      throw new Error('unknown fen piece ' + fenPieceType);
  }
};

const expand = (board: string) =>
  board.replace(/\d/g, n => '-'.repeat(parseInt(n)));

const parseFenPiece = (fenPiece: string) => {
  if (fenPiece === '-') {
    return null;
  }

  return {
    type: getPieceType(fenPiece),
    colour: fenPiece === fenPiece.toUpperCase() ? 'white' : 'black'
  };
};

const parseFenBoard = (fenBoard: string) =>
  fenBoard
    .split('/')
    .map(parseFenRank)
    .reverse();

const parseFenRank = (fenRank: string) =>
  expand(fenRank)
    .split('')
    .map(parseFenPiece);

export const parseFen = (fen: string) => {
  const [fenBoard] = fen.split(' ');
  return parseFenBoard(fenBoard);
};
