import { TouchEvent } from 'react';

export const getTouchPosition = (e: TouchEvent) => {
  const touch = e.touches[0] || e.changedTouches[0];
  const rect = e.currentTarget.getBoundingClientRect();

  const x = touch.clientX - rect.left;
  const y = touch.clientY - rect.top;

  if (x < 0 || x > rect.width || y < 0 || y > rect.height) {
    return null;
  }

  return { x, y };
};
